import { CenteredContainerLg } from '/components/pageOnly/CenteredContainer'
import { Section, SectionGray, SectionWhite } from '/components/home/pageOnly/Section'
import { joinWithDash } from '/machinery/stringUtilities'
import { frequentlyAskedQuestions } from '/data/frequentlyAskedQuestions'
import { TextLg } from '/components/buildingBlocks/Text'
import { FAQBlock } from '/components/home/FAQBlock'
import { Footer } from '/components/pageOnly/Footer'
import { Parallax } from '/machinery/Parallax'
import { Hero } from '/components/home/Hero'

import image480 from '/images/photos/KBM@5k.jpg?max-width=480'
import image768 from '/images/photos/KBM@5k.jpg?max-width=768'
import image1000 from '/images/photos/KBM@5k.jpg?max-width=1000'
import image2000 from '/images/photos/KBM@5k.jpg?max-width=2000'
import image3000 from '/images/photos/KBM@5k.jpg?max-width=3000'
import image4000 from '/images/photos/KBM@5k.jpg?max-width=4000'
import image5000 from '/images/photos/KBM@5k.jpg?max-width=5000'

import styles from './Home.css'

export function Home() {
  return (
    <React.Fragment>
      <HeroSection />
      <ImageSection />
      <FrequentlyAskedSection />
      <FooterSection />
    </React.Fragment>
  )
}

function HeroSection() {
  return (
    <header data-x='header'>
      <SectionGray>
        <CenteredContainerLg>
          <Hero />
        </CenteredContainerLg>
      </SectionGray>
    </header>
  )
}

function ImageSection() {
  return (
    <Parallax dataX='image-parallax' distance={50}>
      <div className={styles.componentImageSection}>
        <img
          alt=""
          width="1000"
          className={styles.image}
          height="667"
          src={image1000}
          srcSet={`
            ${image480}   480w,
            ${image768}   768w,
            ${image1000}  1000w,
            ${image2000}  2000w,
            ${image3000}  3000w,
            ${image4000}  4000w,
            ${image5000}  5000w
          `}
        />
      </div>
    </Parallax>
  )
}

function FrequentlyAskedSection() {
  return (
    <SectionWhite layoutClassName={styles.faq} dataX='faq-section'>
      <CenteredContainerLg>
        <TextLg as="h2">
          Frequently Asked Questions
        </TextLg>
        {frequentlyAskedQuestions.map(({ category, questions }) =>
          <Section key={category} dataX={`faq-${joinWithDash(category)}`}>
            <FAQBlock {... { category, questions }} />
          </Section>
        )}
      </CenteredContainerLg>
    </SectionWhite>
  )
}

function FooterSection() {
  return (
    <footer data-x='footer' className={cx(styles.componentFooterSection)}>
      <SectionWhite>
        <CenteredContainerLg>
          <Footer />
        </CenteredContainerLg>
      </SectionWhite>
    </footer>
  )
}

import styles from './Button.css'

export function Button({ children, onClick, dataX, label = undefined, layoutClassName = undefined }) {
  return (
    <ButtonBase className={styles.component} {...{ children, onClick, label, dataX, layoutClassName }} />
  )
}

function ButtonBase({ children, onClick, dataX, label, className, layoutClassName }) {
  return (
    <button
      type="button"
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentBase, className, layoutClassName)}
      {...{ children, onClick }}
    />
  )
}

import { anchor } from '/machinery/stringUtilities'
import { LINK_TO_KBM_AFFILIPS, LINK_TO_PDF } from './constants'

export const frequentlyAskedQuestions = [
  {
    category: 'Changes within KBM Affilips group',
    questions: [
      {
        question: `Why does the role of KBM Affilips B.V. need to change?`,
        answer: `
          In 1993 KBM Affilips B.V. was founded by parent company Roba Holding to join the
          marketing &amp; sales forces of former competitors Affilips N.V. and KBM Master Alloys B.V. This
          indeed united interests successfully, but the inter-company structure created to support this
          has meanwhile become an obstacle for further business and IT development.
          `
      },
      {
        question: `What changes will I notice?`,
        answer: `
          In the present situation KBM Affilips takes care of the entire sales process including
          invoicing. Starting from January 1 st, 2023, confirmation of agreements and orders, order
          processing and invoicing will be done by the production company (Affilips N.V. or KBM
          Master Alloys B.V.). This means that their logo, address, and bank account information will
          be shown on acknowledgements and invoices. KBM Affilips B.V. will continue to provide
          quotes and product information on behalf of the production companies (as an agent).
          `
      },
      {
        question: `Do I need to make changes to my systems?`,
        answer: `
            We only request you to create the necessary supplier records for the production companies,
            for which ${anchor({ text: 'we supplied details in our letter', href: LINK_TO_PDF, dataX: 'link-in-faq-to-letter-as-pdf' })}.
            People and products at our end remain the same.
          `
      },
      {
        question: `If I am not sure from which production company my products originate, what can I do?`,
        answer: `
          Your KBM Affilips B.V. contact person will be more than happy to assist you in this.
        `
      },
      {
        question: `What is the difference between Affilips N.V. and KBM Master Alloys B.V. products?`,
        answer: `
        Affilips N.V. is located in Belgium and focusses on non-ferro master alloys (aluminium-,
          copper-, nickel-, cobalt-, lead-, zinc-based, etc.). KBM Master Alloys B.V. is located in the
          Netherlands and focusses on aluminium based alloys and PAF. An overview of all available
          products can be found on ${anchor({ text: 'the KBM Affilips website', href: LINK_TO_KBM_AFFILIPS, dataX: 'link-in-faq-to-kbm-affilips' })}.
          `
      }
    ]
  },
  {
    category: 'Orders and certificates',
    questions: [
      {
        question: `If I have a question about my order or invoice, who can I contact?`,
        answer: `
          Your usual contact persons remain the same. KBM Affilips B.V. is still responsible for sales
          quotations and primary customer care; even in case of questions regarding order
          acknowledgements or invoices from Affilips N.V. or KBM Master Alloys B.V.
          `
      },
      {
        question: `What about your management systems certificates?`,
        answer: `
          Both Affilips N.V. and KBM Master Alloys B.V. have valid LRQA certificates. The changing
          role of KBM Affilips B.V. was discussed with the LRQA Lead Auditor and it was decided that
          there is no need for a separate certificate for KBM Affilips B.V. (it was part of the KBM
          certificate anyway). In 2023, when the organisational changes are complete, the sales
          process will be audited again by LRQA.
          `
      },
      {
        question: `Where can I find contact details for both production companies Affilips N.V. and KBM Master Alloys B.V.?`,
        answer: `
            You may find ${anchor({ text: 'more information', href: LINK_TO_KBM_AFFILIPS, dataX: 'link-in-faq-to-kbm-affilips' })} about Affilips N.V. and KBM Master Alloys B.V. on our corporate website.
          `
      }
    ]
  },
  {
    category: 'New corporate brandstyle',
    questions: [
      {
        question: `Can you explain the rebranding in more detail?`,
        answer: `
          The new brand style strives to bring the logos of the KBM Affilips group closer together: from
          triangle to trinity. We are one ‘family’ with strong shared values and the new brand style
          underlines the synergy between the companies. There is more affiliation, without renouncing
          each company’s identity.
          `
      },
      {
        question: `Will all the old company logos immediately disappear in 2023?`,
        answer: `
          No, in the context of our responsibility to the environment certain logo carriers (labels etc.)
          will be used up first.
          `
      }
    ]
  },
]

import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { animated, easings, useSpring } from 'react-spring'
import { lerp } from '@kaliber/math'

export function Parallax({ children, distance, dataX = undefined, layoutClassName = undefined }) {
  const [{ y, clipPath, opacity }, api] = useSpring(() => ({
    config: { tension: 300, friction: 35, config: { easing: easings.easeInCubic } },
    clipPath: 0,
    opacity: 0,
    y: 0
  }))

  const trackedElementRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange(progression) {
      api.start({
        y: lerp({ start: 0, end: -distance, input: progression }),
        clipPath: lerp({ start: 0, end: distance, input: progression }),
        opacity: lerp({ start: 0, end: 0.75, input: progression })
      })
    }
  })

  return (
    <div ref={trackedElementRef} data-x={dataX} className={layoutClassName}>
      <animated.div
        style={{
          y,
          marginBottom: -(distance),
          clipPath: clipPath.to(x => `inset(0px 0px -${x}px 0px)`),
          filter: opacity.to(x => `drop-shadow(0 -30px 50px rgba(0, 0, 0, ${x}))`),
          contain: 'layout paint style'
        }}
      >
        {children}
      </animated.div>
    </div>
  )
}

import { TextXxl } from '/components/buildingBlocks/Text'
import { Accordeon } from '/components/pageOnly/Accordeon'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { animated, useSpring } from 'react-spring'

import styles from './FAQBlock.css'

export function FAQBlock({ category, questions }) {
  const { ref: elementRef, wasInViewport } = useIsInViewport()
  const style  = useSpring({
    opacity: wasInViewport ? 1 : 0,
    y: wasInViewport ? 0 : 10,
    delay: 150
  })

  return (
    <animated.div id={encodeURI(category)} ref={elementRef} className={styles.component} {... { style }}>
      <TextXxl as="h3" layoutClassName={styles.category}>
        {category}
      </TextXxl>
      {questions.map(({ question, answer }) =>
        <Accordeon key={question} layoutClassName={styles.question} {... { question, answer }} />
      )}
    </animated.div>
  )
}

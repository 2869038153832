import { LINK_TO_KBM_AFFILIPS, LINK_TO_PDF, LINK_TO_PRIVACY } from '/data/constants'
import { LogoKbmAffilipsBlackPurple, LogoKbmBlackRed, LogoAffilipsBlackBlue } from '/components/pageOnly/Logo'
import { TextLg, TextSm } from '/components/buildingBlocks/Text'
import { LinkText } from '/components/buildingBlocks/Link'

import styles from './Footer.css'

export function Footer() {
  return (
    <div className={styles.component}>
      <MoreQuestions />
      <Links />
      <LogoContainer />
    </div>
  )
}

function MoreQuestions() {
  return (
    <Row layoutClassName={cx(styles.componentMoreQuestions, styles.grid)}>
      <TextLg as="h4">
        Got another question?
      </TextLg>
      <TextSm layoutClassName={styles.moreQuestionsParagraph}>
        If you have another question, our staff will be more than pleased to assist you.
      </TextSm>
    </Row>
  )
}

function Links() {
  return (
    <Row layoutClassName={styles.grid}>
      <Column>
        <LinkText label='Link to KBM Affilips' dataX='link-in-footer-to-kbm-affilips' to={LINK_TO_KBM_AFFILIPS}>
          KBM Affilips
        </LinkText>
      </Column>
      <Column>
        <LinkText dataX='link-in-footer-to-letter-as-pdf' to={LINK_TO_PDF}>
          Download letter as PDF
        </LinkText>
      </Column>
      <Column>
        <LinkText target='_blank' to={LINK_TO_PRIVACY} onClick={handleClick} dataX='link-in-footer-to-privacy-policy' label='Link to privacy policy'>
          Privacy Policy
        </LinkText>
      </Column>
    </Row>
  )
  function handleClick() {
    window.open(LINK_TO_PRIVACY)
  }
}

function LogoContainer() {
  return (
    <div className={cx(styles.componentLogoContainer, styles.grid)}>
      <LogoKbmAffilipsBlackPurple />
      <LogoKbmBlackRed />
      <LogoAffilipsBlackBlue />
    </div>
  )
}

function Row({ children, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentRow, layoutClassName)}>
      {children}
    </div>
  )
}

function Column({ children }) {
  return (
    <span className={styles.componentColumn}>
      {children}
    </span>
  )
}

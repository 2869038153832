import { animated, easings, useSpring } from 'react-spring'
import styles from './Chevron.css'

export function Chevron({ opened }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <Polyline {...{ opened }} />
    </svg>
  )
}

function Polyline({ opened }) {
  const { x } = useSpring({
    from: { x: 0 },
    x: opened ? 1 : 0,
    config: {
      mass: 200,
      bounce: 0.25,
      friction: 100,
      duration: 150,
      easing:
        opened
          ? easings.easeOutBounce
          : easings.easeOutCirc
    },
    onRest: (_, api) => {
      if (opened) api.start({
        x: 2,
        delay: 200,
        config: {
          duration: 100
        }
      })
    }
  })

  return (
    <animated.polyline
      className={styles.componentPolyline}
      points={x.to({
        range: [0, 1, 2],
        output: [
          '0 10, 10 18, 20 10',
          '0 10, 10 14, 20 10',
          '0 10, 10 10, 20 10'
        ]
      })}
    />
  )
}

import { useElementSize } from '@kaliber/use-element-size'
import { TextSm } from '/components/buildingBlocks/Text'
import { Chevron } from '/components/buildingBlocks/Chevron'
import { animated, easings, useSpring } from 'react-spring'
import { v4 as uuid } from 'uuid'

import styles from './Accordeon.css'

export function Accordeon({ question, answer, layoutClassName }) {
  const [opened, setOpened] = React.useState(false)
  const id = useId()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Question {... { id, opened }} onClick={handleClick}>
        {question}
      </Question>
      <Answer {... { id, opened }}>
        {answer}
      </Answer>
    </div>
  )

  function handleClick() {
    setOpened(!opened)
  }
}

function Question({ id, children, opened, onClick }) {
  return (
    <button
      type="button"
      id={`button-${id}`}
      aria-controls={id}
      aria-expanded={opened}
      data-x={opened ? 'close-faq-item' : 'open-faq-item'}
      className={cx(styles.componentQuestion)}
      {... { onClick }}
    >
      <TextSm layoutClassName={styles.innerQuestionPadding}>
        {children}
      </TextSm>
      <Chevron {... { opened }} />
    </button>
  )
}

function Answer({ id, children, opened }) {
  const { ref: elementRef, size: { height } } = useElementSize()
  const { maxHeight } = useSpring({
    maxHeight: opened ? height : 0,
    config: {
      easing: easings.easeInCubic
    }
  })

  return (
    <animated.div role="region" aria-labelledby={`button-${id}`} className={styles.componentAnswer} style={{ maxHeight }}>
      <div className={styles.innerAnswer} ref={elementRef}>
        <TextSm layoutClassName={styles.innerAnswerPadding} >
          <span className={styles.inner} dangerouslySetInnerHTML={{ __html: children }} />
        </TextSm>
      </div>
    </animated.div>
  )
}

function useId() {
  const [id, setId] = React.useState(null)
  React.useEffect(() => { setId(uuid()) }, [])

  return id
}

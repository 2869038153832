
import { TextLg, TextMd, TextSm } from '/components/buildingBlocks/Text'
import { AnimateChildren } from '/machinery/AnimateChildren'
import { Button } from '/components/buildingBlocks/Button'
import { Header } from '/components/pageOnly/Header'
import { ScrollIndicator } from '/components/buildingBlocks/ScrollIndicator'
import { LINK_TO_PDF } from '/data/constants'

import styles from './Hero.css'

export function Hero() {
  const heroRef = React.useRef(null)
  return (
    <div ref={heroRef} className={styles.component}>
      <div className={styles.fullHeightContainer}>
        <Header />
        <Introduction {... { heroRef }} />
      </div>
    </div>
  )
}

function Introduction({ heroRef }) {
  return (
    <div className={styles.componentIntroduction}>
      <AnimateChildren>
        <TextMd layoutClassName={styles.text}>
          Welcome to our Frequently Asked Questions
        </TextMd>
        <TextLg layoutClassName={styles.text}>
          In addition to the letter we sent you, we launched this dedicated webpage to provide more details and answer questions that may arise regarding the changes within our group.
          Of course, your present contact(s) at KBM Affilips will be more than pleased to offer further information where required.
          We thank you in advance for your support in this transition and express our conviction that it will add value to our business relationship.
        </TextLg>
        <Button onClick={handleDownloadClick} dataX='click-to-download-letter' layoutClassName={styles.button}>
          <TextSm>
            Download the letter
          </TextSm>
        </Button>
        <ScrollIndicator onClick={handleClick} />
      </AnimateChildren>
    </div>
  )
  function handleClick() {
    window.scrollTo({ top: heroRef.current.offsetTop + heroRef.current.offsetHeight, behavior: 'smooth' })
  }

  function handleDownloadClick() {
    window.open(LINK_TO_PDF, '_blank')
  }
}

import { TextSm } from '/components/buildingBlocks/Text'
import { Icon } from '/components/buildingBlocks/Icon'

import styles from './ScrollIndicator.css'

import arrow from '/images/icons/arrow.raw.svg'

export function ScrollIndicator({ onClick }) {
  return (
    <button {... { onClick }} className={styles.component}>
      <TextSm>See our FAQ</TextSm>
      <Icon layoutClassName={styles.iconLayout} icon={arrow} />
    </button>
  )
}
